import { Meetings, MeetingsActions } from 'types/Meetings';
import { call, put } from "redux-saga/effects";
import { handleGenerateError, meetingsApiRequest, imageApiRequest } from 'redux/api';
import {
  showLoader,
  hideLoader,
  authLogout,
  showSnackbar,
  setMeetings,
  setMeeting,
  getMeeting,
  setRSVPSent,
  setHeroImage,
  setLogoImage,
  setLoading,
  deleteComplete,
  putGameComplete
} from 'redux/actions';
import { PAGE_SIZE } from '../constants';
import { EventImageType } from 'types/Enums';
import axios from 'axios';

export function* meetingsAPISaga(action: Meetings.TActions.APIActions) {
  try {
    if (action.type === MeetingsActions.GET_ALL) {
      yield put(showLoader('meetings-list'));
      const response = yield call(meetingsApiRequest, {
        method: 'GET',
        url: `/meetings?pageNumber=${action.payload}&pageSize=${PAGE_SIZE}&sortBy=-createdOnUtc`
      });
      yield put(setMeetings(response.data));
      yield put(hideLoader('meetings-list'));
    } else if (action.type === MeetingsActions.GET) {
      yield put(setLoading(true));
      let url = `/meetings/${action.payload.meetingId}`;
      if (action.payload.public) {
        url += '/public';
      }
      const response = yield call(meetingsApiRequest, {
        method: 'GET',
        url: url
      });
      yield put(setMeeting(response.data));
      yield put(setLoading(false));
    } else if (action.type === MeetingsActions.CREATE) {
      yield put(showLoader('meeting'));

      const response = yield call(meetingsApiRequest, {
        method: 'POST',
        url: '/meetings',
        payload: action.payload
      });

      let url = `/meetings/${response.data.id}`;
      const meetingResponse = yield call(meetingsApiRequest, {
        method: 'GET',
        url: url
      });

      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/vnd.archiebot.v1+json");

      const tokenFormdata = new FormData();
      tokenFormdata.append("grant_type", "password");
      tokenFormdata.append("client_id", "AgpayyJSzMqSNVCaNYpJLWVUhQ4otpJERoHgfFH1");
      tokenFormdata.append("client_secret", "eaf1ded6aa202aa618a8f35630d91bba");
      tokenFormdata.append("username", "avdemo-matt@livewebinar.com");
      tokenFormdata.append("password", "dMWp!MfpfniRON764NEsnc");

      const retrieveToken = yield axios({
          method: 'post',
          url: `https://api.archiebot.com/api/oauth/access_token`,
          data: tokenFormdata,
          headers: {
              'Content-Type': 'multipart/form-data',
              Accept: 'application/vnd.archiebot.v1+json'
          }
      });
      let { access_token } = retrieveToken.data.accessToken;

      var meetingFormdata = new FormData();
      meetingFormdata.append(`name`, `Communities Room ${meetingResponse.data.code}`);
      meetingFormdata.append(`custom_name`, `Communities Room ${meetingResponse.data.code}`);
      meetingFormdata.append(`type`, `scheduled`);
      meetingFormdata.append(`custom_template[css][0][url]`, `https://experience.xureal.com/3dworld/css/live-webinar.css`);
      meetingFormdata.append(`duration`, `120`);
      meetingFormdata.append(`timeZone`, `${Intl.DateTimeFormat().resolvedOptions().timeZone}`);

      const createVideoRoom = yield axios({
          method: 'post',
          url: `https://api.archiebot.com/api/widgets`,
          data: meetingFormdata,
          headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${access_token}`,
              Accept: 'application/vnd.archiebot.v1+json'
          }
      });
      console.log('Created video room', createVideoRoom);

      yield put(getMeeting(response.data.id, false));
      yield put(hideLoader('meeting'));
    } else if (action.type === MeetingsActions.RESPOND_INVITATION) {
      yield put(showLoader('meeting'));
      const response = yield call(meetingsApiRequest, {
        method: 'POST',
        url: `meetings/${action.payload.meetingId}/response`,
        payload: {
          id: action.payload.meetingId,
          email: action.payload.response.email,
          action: action.payload.response.action
        }
      });

      yield put(setRSVPSent(action.payload.meetingId, response.status === 200));
      yield put(hideLoader('meeting'));
    } else if (action.type === MeetingsActions.UPDATE) {
      yield put(showLoader('meeting'));
      const response = yield call(meetingsApiRequest, {
        method: 'PUT',
        url: `meetings/${action.payload.meetingId}`,
        payload: action.payload.meeting
      });
      yield put(getMeeting(response.data.id, false));
      yield put(hideLoader('meeting'));
    } else if (action.type === MeetingsActions.DELETE) {
      yield put(showLoader('meeting'));
      yield call(meetingsApiRequest, {
        method: 'DELETE',
        url: `meetings/${action.payload}`
      });
      yield put(deleteComplete(action.payload));
      yield put(hideLoader('meeting'));
    } else if (action.type === MeetingsActions.SET_IMAGE) {
      yield put(showLoader('image-upload'));
      const formData = new FormData();
      formData.append('file', action.payload.data);
      const response = yield call(imageApiRequest, {
        method: 'POST',
        url: `content/file/upload?fileType=${action.payload.type}`,
        payload: formData
      });
      if (action.payload.type === EventImageType.HeroImage) {
        yield put(setHeroImage(response.data.url));
      } else {
        yield put(setLogoImage(response.data.url));
      }
      yield put(hideLoader('image-upload'));
    } else if (action.type === MeetingsActions.PUT_GAMES) {
      yield put(showLoader('meeting'));
      const response = yield call(meetingsApiRequest, {
        method: 'PUT',
        url: `meetings/${action.payload.id}/games`,
        payload: action.payload
      });
      yield put(putGameComplete(response.data));
      yield put(hideLoader('meeting'));
    }
  } catch (error) {
    const uiError = handleGenerateError(error);
    console.error('Meetings Generated uiError: ', uiError);
    if (uiError.code === 401) {
      yield put(authLogout());
    } else {
      yield put(
        showSnackbar({
          opened: true,
          data: {
            message: uiError.message,
            type: 'error'
          }
        })
      )
    }
    if (action.type === MeetingsActions.GET) yield put(hideLoader('meeting'));
    if (action.type === MeetingsActions.GET_ALL) yield put(hideLoader('meetings-list'));
  }
}